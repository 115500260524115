import React from 'react';

interface CustomerStoryIntroProps {
  title: string;
  body: string;
}

const CustomerStoryIntro: React.FC<CustomerStoryIntroProps> = ({
  title,
  body,
}) => (
  <section className="py-16 border-b lg:py-32 border-keyline">
    <div className="space-y-8 lg:flex u-container lg:space-x-20 lg:space-y-0">
      <div className="lg:w-4/12">
        <h2 className="u-h2">{title}</h2>
      </div>
      <div className="lg:w-7/12">
        <div
          className="prose md:prose-xl"
          dangerouslySetInnerHTML={{ __html: body.replace(/\n/g, '<br />') }}
        />
      </div>
    </div>
  </section>
);

export default CustomerStoryIntro;
