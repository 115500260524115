import { graphql } from 'gatsby';
import Seo from '../components/base/Seo';
import React from 'react';
import Layout from '../components/base/Layout';
import CustomerStoryHero from '../components/organisms/CustomerStoryHero';
import CustomerStoryIntro from '../components/organisms/CustomerStoryIntro';
import CustomerStoryQuote from '../components/organisms/CustomerStoryQuote';
import CustomerStoryVideo from '../components/organisms/CustomerStoryVideo';
import CustomerStoryImage from '../components/organisms/CustomerStoryImage';
import CustomerStoryWysiwyg from '../components/organisms/CustomerStoryWysiwyg';
import FeaturedResources from '../components/organisms/FeaturedResources';

const CustomerStoryPage = ({ data: { page, allCustomerStories } }) => {
  const resources = allCustomerStories.nodes
    .filter((resource) => resource.slug !== page.slug)
    .sort((a, b) => {
      const aValue =
        a.customerStory.solutions.filter((solution) =>
          page.customerStory.solutions.includes(solution),
        ).length +
        a.customerStory.industries.filter(({ industry }) =>
          page.customerStory.industries
            .map(({ industry }) => industry)
            .includes(industry),
        ).length *
          3;

      const bValue =
        b.customerStory.solutions.filter((solution) =>
          page.customerStory.solutions.includes(solution),
        ).length +
        b.customerStory.industries.filter(({ industry }) =>
          page.customerStory.industries
            .map(({ industry }) => industry)
            .includes(industry),
        ).length *
          3;

      return aValue - bValue;
    })
    .splice(0, 3);

  return (
    <Layout>
      <Seo post={page} />

      <CustomerStoryHero
        title={page.title}
        benchmarks={page.customerStory.benchmarks}
        image={page.customerStory.heroImage}
        logo={page.customerStory.heroLogo}
        graphicType={page.customerStory.customerHeroGraphicType}
        video={page.customerStory.customerHeroVideo}
      />
      <div className="relative bg-white">
        {page?.customerStory?.customerStoryContent?.map((props, index) => {
          const mapping = {
            customerStory_Customerstory_CustomerStoryContent_Intro: (
              <CustomerStoryIntro key={index} {...props} />
            ),
            customerStory_Customerstory_CustomerStoryContent_Wysiwyg: (
              <CustomerStoryWysiwyg key={index} {...props} />
            ),
            customerStory_Customerstory_CustomerStoryContent_Quote: (
              <CustomerStoryQuote key={index} {...props} />
            ),
            customerStory_Customerstory_CustomerStoryContent_FullWidthVideo: (
              <CustomerStoryVideo key={index} {...props} />
            ),
            customerStory_Customerstory_CustomerStoryContent_FullWidthImage: (
              <CustomerStoryImage key={index} {...props} />
            ),
          };

          return props.fieldGroupName in mapping
            ? mapping[props.fieldGroupName]
            : null;
        })}
      </div>
      <div className="border-b border-keyline" />
      <FeaturedResources title="More Stories" resources={resources} noBorder />
    </Layout>
  );
};

export const pageQuery = graphql`
  query CustomerStoryQuery($id: String!) {
    page: wpCustomerStory(id: { eq: $id }) {
      ...CustomerStorySEO
      date
      title
      nodeType
      slug
      featuredImage {
        node {
          ...Image
        }
      }
      customerStory {
        logo {
          ...Image
        }
        solutions
        industries {
          industry
        }
        benchmarks {
          label
          value
        }
        customerHeroGraphicType
        customerHeroVideo {
          localFile {
            publicURL
          }
        }
        heroImage {
          ...Image
        }
        heroLogo {
          ...Image
        }
        customerStoryContent {
          ... on WpCustomerStory_Customerstory_CustomerStoryContent_Wysiwyg {
            content
            image {
              ...Image
            }
            imageSide
            fieldGroupName
          }
          ... on WpCustomerStory_Customerstory_CustomerStoryContent_FullWidthVideo {
            embedUrl
            caption
            fieldGroupName
          }
          ... on WpCustomerStory_Customerstory_CustomerStoryContent_FullWidthImage {
            image {
              ...Image
            }
            caption
            fieldGroupName
          }
          ... on WpCustomerStory_Customerstory_CustomerStoryContent_Quote {
            quote
            author {
              photo {
                ...Image
              }
              name
              title
            }
            fieldGroupName
          }
          ... on WpCustomerStory_Customerstory_CustomerStoryContent_Intro {
            fieldGroupName
            title
            body
          }
        }
      }
    }
    allCustomerStories: allWpCustomerStory(
      sort: { fields: dateGmt, order: DESC }
    ) {
      nodes {
        date
        title
        nodeType
        slug
        featuredImage {
          node {
            ...Image
          }
        }
        customerStory {
          logo {
            ...Image
          }
          solutions
          industries {
            industry
          }
        }
      }
    }
  }
`;

export default CustomerStoryPage;
