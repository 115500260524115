import React from 'react';
import { IImage } from '../../interfaces/image';

interface CustomerStoryWysiwygProps {
  content: string;
  image: IImage;
  imageSide: 'Left' | 'Right';
}

const CustomerStoryWysiwyg: React.FC<CustomerStoryWysiwygProps> = ({
  content,
  image,
  imageSide,
}) => (
  <section className="my-16 lg:my-32">
    <div
      className={`items-center lg:space-x-24 ${image && 'lg:flex'} ${
        image && imageSide === 'Right' && 'flex-row-reverse lg:space-x-reverse'
      } u-container`}
    >
      {image && (
        <div className="lg:w-1/2">
          <img
            src={image?.sourceUrl}
            alt={image?.altText}
            className="w-full h-auto"
          />
        </div>
      )}
      <div className={image && 'lg:w-1/2 pt-15 lg:pt-0'}>
        <div
          className={`prose md:prose-lg ${!image && 'mx-auto'}`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  </section>
);

export default CustomerStoryWysiwyg;
