import React from 'react';

interface CustomerStoryVideoProps {
  embedUrl: string;
  caption: string;
}

const CustomerStoryVideo: React.FC<CustomerStoryVideoProps> = ({
  embedUrl,
  caption,
}) => (
  <section className="my-16 lg:my-32">
    <div className="u-container">
      <div className="max-w-lg">
        <div className="prose" dangerouslySetInnerHTML={{ __html: caption }} />
      </div>
      <div className="mt-10 aspect-w-16 aspect-h-9">
        <iframe
          width="560"
          height="315"
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

    </div>
  </section>
);

export default CustomerStoryVideo;
