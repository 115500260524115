import React from 'react';
import { IImage } from '../../interfaces/image';

interface CustomerStoryQuoteProps {
  quote: string;
  author: {
    name: string;
    title: string;
    photo: IImage;
  };
}

const CustomerStoryQuote: React.FC<CustomerStoryQuoteProps> = ({
  quote,
  author,
}) => (
  <div className="my-16 md:my-32">
    <section
      className="py-16 text-center text-white bg-cover md:py-32"
      style={{ backgroundImage: 'url(/images/customer-story-quote-bg.jpg)' }}
    >
      <div className="u-container">
        <q className="max-w-4xl mx-auto text-2xl font-semibold leading-snug leading-10 lg:text-4xl quotes-none lg:leading-12">
          {quote}
        </q>

        <div className="mt-12 text-center">
          {author.photo && (
            <img
              src={author.photo.sourceUrl}
              alt={author.photo.altText}
              className="mx-auto mb-5 rounded-full w-15 h-15"
            />
          )}
        </div>
        <div className="font-semibold leading-6">{author.name}</div>
        <div className="leading-6">{author.title}</div>
      </div>
    </section>
  </div>
);

export default CustomerStoryQuote;
