import React from 'react';
import Image from '../base/Image';

interface CustomerStoryVideoProps {
  image: any;
  caption: string;
}

const CustomerStoryVideo: React.FC<CustomerStoryVideoProps> = ({
  image,
  caption,
}) => (
  <section className="my-16 lg:my-32">
    <div className="u-container">
      {/* <div className="mb-10 aspect-w-16 aspect-h-9"> */}
      <Image data={image} className="w-full mb-10" />
      {/* </div> */}
      <div className="max-w-lg">
        <div className="prose" dangerouslySetInnerHTML={{ __html: caption }} />
      </div>
    </div>
  </section>
);

export default CustomerStoryVideo;
