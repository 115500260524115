import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { IImage } from '../../interfaces/image';
import Image from '../base/Image';

interface CustomerStoryHeroProps {
  title: string;
  benchmarks: Array<{ label: string; value: string }>;
  image: IImage;
  logo?: IImage;
  graphicType?: string;
  video?: any;
}

const CustomerStoryHero: React.FC<CustomerStoryHeroProps> = ({
  title,
  benchmarks,
  image,
  logo,
  graphicType,
  video,
}) => {
  const absoluteMap = {
    'facebook-4-5': { top: '26.5%', left: '1px', bottom: '6.4%', right: '1px' },
    'facebook-1-1': { top: '26.5%', left: '1px', bottom: '19.85%', right: '1px' },
    'facebook-16-9': { top: '26.5%', left: '1px', bottom: '43.2%', right: '1px' },
    'facebook-9-16': { top: '4%', left: '1px', bottom: '1px', right: '1px' },
    'insta-4-5': { top: '18%', left: '1px', bottom: '14%', right: '1px' },
    'insta-1-1': { top: '18%', left: '1px', bottom: '27%', right: '1px' },
    'insta-16-9': { top: '18%', left: '1px', bottom: '51%', right: '1px' },
    'insta-9-16': { top: '5%', left: '1px', bottom: '1px', right: '1px' },
    'twitter-1-1': { top: '30.9%', left: '5%', bottom: '19.3%', right: '5%', borderRadius: '0.5rem' },
    'twitter-16-9': { top: '30.9%', left: '5%', bottom: '35.3%', right: '5%', borderRadius: '0.5rem' },
    'email-1-1': { top: '31.7%', left: '1px', bottom: '14%', right: '1px' },
    'email-16-9': { top: '31.7%', left: '1px', bottom: '37%', right: '1px' },
    'email-4-5': { top: '31%', left: '1px', bottom: '2.2%', right: '1px' },
    'email-laptop-16-9': { top: '9%', left: '16%', bottom: '15%', right: '16%' },
    'email-laptop-1-1': { top: '9%', left: '30%', bottom: '15%', right: '30%' },
    snap: {
      top: '0%',
      left: '0',
      bottom: '0',
      right: '0',
      borderTopLeftRadius: '0.9rem',
      borderTopRightRadius: '0.9rem',
      zIndex: '0',
    },
  };

  const {
    wp: {
      options: {
        globalOptions: { customerStoryBackButtonLabel },
      },
    },
    heroBackground,
  } = useStaticQuery(
    graphql`
      query {
        wp {
          options {
            globalOptions {
              customerStoryBackButtonLabel
            }
          }
        }
        heroBackground: file(name: { eq: "customer-story-hero-bg" }) {
          ...FileImageBase64
        }
      }
    `,
  );

  const isLaptop =
    graphicType === 'email-laptop-16-9' || graphicType === 'email-laptop-1-1';
  const containingStyles = isLaptop ? { marginRight: '-40%' } : {};


  return (
    <section className="relative pt-16 overflow-hidden text-white bg-cover lg:pt-20 xl:pt-32">
      <div className="absolute inset-0 z-0">
        <Image
          loading="eager"
          data={{ localFile: heroBackground }}
          className="object-cover w-full h-full pointer-events-none"
          objectFit="cover"
        />
      </div>

      <div className="u-container">
        <div
          className={`relative flex flex-wrap ${isLaptop ? 'items-center' : 'items-end'
            } -ml-12`}
        >
          <div className={`pl-12 w-full md:w-8/12`}>
            <Link
              to="/customers"
              className="inline-flex items-center mb-8 font-semibold md:mb-16"
            >
              <img
                src="/images/back-arrow.svg"
                alt=""
                className="mr-2.5 w-auto h-3"
              />
              {customerStoryBackButtonLabel}
            </Link>

            {logo && (
              <Image data={logo} className="w-auto h-8 mb-8 md:h-12 md:mb-12" />
            )}

            <h1
              className="mb-8 u-h2 md:mb-14"
              dangerouslySetInnerHTML={{ __html: title }}
            />

            {benchmarks && (
              <div className="flex flex-wrap max-w-xl pb-16 lg:flex-nowrap lg:pb-28 lg:space-x-12">
                {benchmarks.map((benchmark, index) => (
                  <div
                    className="w-1/2 pb-8 pr-8 lg:flex-1 lg:w-auto lg:pr-0 lg:pb-0"
                    key={index}
                  >
                    <div className="mb-1 text-3xl font-semibold lg:text-5xl">
                      {benchmark.value}
                    </div>
                    <div className="text-sm">{benchmark.label}</div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {(image || (video && video.localFile && video.localFile.publicURL)) &&
            graphicType && (
              <div className="flex flex-col items-center w-full pl-12 md:items-end md:w-4/12">
                <div className={`${!isLaptop ? 'max-w-xs' : ''}`}>
                  <div
                    className="relative inline-block top-2"
                    style={containingStyles}
                  >
                    <img
                      src={`/images/customer-screens/${graphicType}.svg?v=2.0.0`}
                      alt={graphicType}
                      className="relative z-10"
                    />
                    <div
                      className="absolute inset-0 overflow-hidden bg-true-gray"
                      style={absoluteMap[graphicType]}
                    >
                      {video && video.localFile && video.localFile.publicURL ? (
                        <video
                          poster={image?.localFile?.publicURL}
                          src={video.localFile.publicURL}
                          loop={true}
                          autoPlay={true}
                          playsInline={true}
                          muted={true}
                          className="object-cover w-full h-full"
                        />
                      ) : (
                        <Image
                          loading="eager"
                          data={image}
                          className="object-cover w-full h-full"
                          objectFit="cover"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </section>
  );
};

export default CustomerStoryHero;
